<template>
  <div class="async-progress">
    <t-progress horizontal gray s />
  </div>
</template>

<style scoped lang="less">
.async-progress {
  display: flex;
}
.t-progress {
  margin: auto;
}
</style>
