<template>
  <div v-if="show && specialOfferData">
    <teleport to="#ml-specialOfferTeaser">
      <special-offer-teaser :special-offer-data="specialOfferData" />
      <special-offer-promotion-styles />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgressHorizontal.vue'

const SpecialOfferTeaser = defineAsyncComponent({
  loader: () => {
    return import(/* webpackChunkName: "special-offer-promotion" */ '@gd/special-offer-promotion').then(
      (SpecialOfferPromotion) => {
        return SpecialOfferPromotion.SpecialOfferTeaser
      },
    )
  },
  loadingComponent: AsyncProgress,
})
const SpecialOfferPromotionStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "special-offer-promotion" */ './SpecialOfferStyles.vue'),
})

const show = ref(false)
const specialOfferData = ref<GD_notifications.SpecialOfferData>()
const render = document.getElementById('ml-specialOfferTeaser')
if (render && render.dataset.value) {
  try {
    specialOfferData.value = JSON.parse(render.dataset.value)
    show.value = true
  } catch (err) {
    console.error("Parsing error. Can't parse specialOfferTeaser data-value", err)
  }
}
</script>

<style lang="less">
// center the horizontal loading progress while loading the async files 
.ml-specialOfferTeaser {
  display: flex;
  justify-content: center;
}
</style>
