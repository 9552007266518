<template>
  <div class="module-loader">
    <gd-modal v-if="showModal && !persistent" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <modal-app m>
        <technology-warning
          class="tech-hint"
          @close="handleModalClose"
          :gametype="gametype"
          :user-name="username"
          :reason="reason"
        />
      </modal-app>
    </gd-modal>
    <gd-modal v-if="showModal && persistent">
      <modal-app s>
        <technology-warning
          @close="handleModalClose"
          class="tech-hint"
          :gametype="gametype"
          :persistent="persistent"
          :user-name="username"
          :reason="reason"
        />
      </modal-app>
    </gd-modal>
    <technology-warning-styles />
  </div>
</template>

<script setup lang="ts">
import { GD, Storage, STORE_NAME, User } from '@gd/storage'
import AsyncProgress from './AsyncProgress.vue'
import { computed, defineAsyncComponent, Ref, ref } from 'vue'
import events from '@gd/event-bus'

interface Conditions {
  screensize: { minWidth: number } // min width, maybe extended later
  notSupported: 'always' | 'needsMouse' | 'needsTouch'
}

interface GameTech {
  gametype?: string
  persistent?: boolean
  close?: boolean
  // reason is an optional thing - and should only be passed for 'notSupported'
  // -> everything else is handled inside the technology-warning -modal
  reason?: 'tech' | 'touch' | 'screensize' | 'notSupported'
  conditions?: Conditions
}

const TechnologyWarning = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "game-technologies-check" */ '../importers/TechnologyWarning'),
  loadingComponent: AsyncProgress,
})
const TechnologyWarningStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "game-technologies-check" */ './GameTechnologiesCheckStyles.vue'),
})

const showModal = ref(false)
const persistent = ref(false)
const gametype = ref('')
const reason: Ref<'tech' | 'touch' | 'screensize' | 'notSupported' | undefined> = ref(undefined)

events.$on('game-tech-mismatch', (data?: GameTech) => {
  // close the tech-hint
  if (data && data.close === true) {
    showModal.value = false
    return
  }
  if (data && data.conditions) {
    reason.value = handleConditionsEvent(data.conditions)
    if (reason.value) {
      showModal.value = true
      persistent.value = (data && data.persistent) || false
      doTrack()
    }
  } else {
    reason.value = (data && data.reason) || undefined // undefined is duplicated but it should point out that it is a valid case to fallback to default
    gametype.value = (data && data.gametype) || ''
    showModal.value = true
    persistent.value = (data && data.persistent) || false
    doTrack()
  }
})

const handleConditionsEvent = (conditions: Conditions) => {
  let reason: 'tech' | 'touch' | 'screensize' | 'notSupported' | undefined = undefined
  if (conditions.screensize) {
    reason = handleScreensize(conditions.screensize)
  }
  if (!reason && conditions.notSupported) {
    reason = handleNotSupported(conditions.notSupported)
  }
  return reason
}

const handleScreensize = (screensize: { minWidth: number }) => {
  const storageGD: GD = new Storage().read(STORE_NAME.GD)
  if (storageGD.mobileDevice) {
    return screensize.minWidth > window.screen.width ? 'screensize' : undefined
  } else {
    return screensize.minWidth > window.innerWidth ? 'screensize' : undefined
  }
}

const handleNotSupported = (notSupported: 'always' | 'needsMouse' | 'needsTouch') => {
  switch (notSupported) {
    case 'always':
      return 'notSupported'
    case 'needsMouse':
      return matchMedia('(pointer:fine)').matches ? undefined : 'notSupported'
    case 'needsTouch':
      return window.navigator.maxTouchPoints > 0 ? undefined : 'touch'
  }
}

const doTrack = () => {
  const storageUser: User = new Storage().read(STORE_NAME.USER)
  fetch(`/gd/tracking?key=game-technology-modal&user_id=${storageUser.id}&gametype="${gametype.value}"&action=open`, {
    credentials: 'same-origin',
  }).catch((err) => {
    console.error('tracking failed')
  })
}

const handleModalClose = ({
  persistent,
  reason,
}: {
  persistent: boolean
  reason: 'tech' | 'touch' | 'screensize' | 'notSupported'
}) => {
  if (reason === 'tech') {
    // button in modal is clicked and we redirect to compatibility page
    document.location.href = '/gd/support/compatibility.xhtml'
  } else if (!persistent) {
    // if not persistent we handle the modal as hint and the user can just close it
    showModal.value = false
    return
  } else {
    // all other reasons lead to games page (better would be to trigger the browser back button - but this could also
    // lead to other side effects )
    document.location.href = '/gd/gamesPage/gamespage.xhtml'
  }
}

const username = computed(() => {
  return new Storage().read(STORE_NAME.USER).name
})

const onClose = () => {
  const storageUser: User = new Storage().read(STORE_NAME.USER)
  fetch(`/gd/tracking?key=game-technology-modal&user_id=${storageUser.id}&action=close`, {
    credentials: 'same-origin',
  }).catch((err) => {
    console.error('tracking failed')
  })
  showModal.value = false
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.tech-hint {
  min-height: 15em;
}
</style>
