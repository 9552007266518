<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <modal-app :size="modalSize">
        <customer-service-form
          class="customer-service-app"
          :preselections="preselectionData"
          @close-failed="onError"
          @close="onClose"
          @submitted="onSubmit"
        />
        <customer-service-form-styles />
      </modal-app>
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { Preselections } from '@gd/customer-service-form/types/src/model/Preselections'
import AsyncProgress from './AsyncProgress.vue'
import { Ref, defineAsyncComponent, ref } from 'vue'
import events from '@gd/event-bus'

const CustomerServiceForm = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "cs-form" */ '@gd/customer-service-form'),
  loadingComponent: AsyncProgress,
})
const CustomerServiceFormStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "cs-form" */ './CustomerServiceFormStyles.vue'),
})

const show = ref(false)
const preselectionData: Ref<Preselections | undefined> = ref(undefined)

const modalSize: Ref<'s' | 'm' | 'l'> = ref('l')

// Implement in cs-form component
// Ask Szymon if size S is okay?
const onSubmit = () => {
  modalSize.value = 's'
}

events.$on('cs-form', (data: Preselections) => {
  preselectionData.value = { ...data }
  show.value = true
})

events.$on('close-modals', () => {
  show.value = false
})

const onClose = () => {
  show.value = false
  modalSize.value = 'l'
}

const onError = () => {
  onClose()
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.customer-service-app {
  min-height: 15em;
}
</style>
