<template>
  <div class="module-loader">
    <gd-modal v-if="showExpress" :blocked="blocked" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <shop-express
        class="classic-shop-express"
        @onCompleteButton="onSuccess"
        @onErrorButton="onErrorButton"
        @onSettings="onSettings"
        @onShop="onShop"
        @onDepositStart="onDepositStart"
        @onDepositSuccess="onDepositSuccess"
        @onError="onError"
        @onClose="onClose"
      />
      <shop-express-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { GD, Storage, STORE_NAME } from '@gd/storage'
import events from '@gd/event-bus'
import AsyncProgress from './AsyncProgress.vue'
import { defineAsyncComponent, getCurrentInstance, Ref, ref } from 'vue'

const app = getCurrentInstance()?.appContext.app

/**
 * ShopExpress needs to register the store (vuex) with the module-loader app
 */
const ShopExpress = defineAsyncComponent({
  loader: () => {
    return new Promise(async (resolve, reject) => {
      const { registerComponent } = await import(/* webpackChunkName: "shop-express" */ '@gd/shop-express' as any)
      try {
        const component = registerComponent(app)
        resolve(component)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  },
  loadingComponent: AsyncProgress,
})

const ShopExpressStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "shop-express" */ './ShopExpressStyles.vue'),
})

const depositSuccess = ref(false)
const blocked = ref(false)
const showExpress = ref(false)

const abortCallback: Ref<Function | undefined> = ref(undefined)

events.$on('shop-express', () => {
  showExpress.value = true
})

events.$on('close-modals', () => {
  showExpress.value = false
})

const onDepositStart = (abort: Function) => {
  abortCallback.value = abort
  blocked.value = abortCallback.value === undefined
}

const onDepositSuccess = () => {
  depositSuccess.value = true
  blocked.value = false
}

const onError = (obj: any) => {
  blocked.value = false
}

const onErrorButton = (errorCode: string) => {
  switch (errorCode) {
    case 'no-expresspayment-provider':
    case 'no-purchasables':
    case 'no_purchasables_for_express_payment':
    case 'unavailable':
    case '50':
      onShop()
      break
    default:
      showExpress.value = false
  }
}

const onSettings = () => {
  location.href = '/gd/myGameDuell/shopSettings.xhtml'
}

const onShop = () => {
  const storage: GD = new Storage().read(STORE_NAME.GD)
  location.href = `${storage.webCtxPath}/shop-web/index.html`
}

const onSuccess = () => {
  showExpress.value = false
  events.$emit('update-balance')
}

const onClose = () => {
  if (abortCallback.value) {
    abortCallback.value()
  }
  if (depositSuccess.value) {
    onSuccess()
  }
  showExpress.value = false
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.classic-shop-express {
  min-width: 24em;
  max-width: 72em;
  min-height: 15em;
}
</style>
