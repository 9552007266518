<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeESC="onClose" @close="onClose">
      <bonus-infusion class="bonus-infusion" @close="onSuccess" @error="onError" size="s" />
      <bonus-infusion-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'
import { jslog } from '@gd/jslog'

const BonusInfusion = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "bonus-infusion" */ '@gd/bonus-infusion'),
  loadingComponent: AsyncProgress,
})
const BonusInfusionStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "bonus-infusion" */ './BonusInfusionStyles.vue')
})

  const show = ref(false)
  const threshold = ref('0')
  const amount = ref('0')

  events.$on('notifications', (data: Array<any>) => {
    if (data.length > 0) {
      data.forEach((item: any) => {
        if (item.type === 'bonusInfusion') {
          show.value = true
        }
      })
    }
  })

  const onSuccess = () => {
    show.value = false
    events.$emit('update-balance')
  }

  const onError = (err: any) => {
    // for the first we ignore errors
    jslog.send({ name: 'bonus-infusion-modal', error: err })
    show.value = false
  }

  const onClose = () => {
    fetch('/gd/rest/me/notifications/bonusInfusion', {
      method: 'DELETE',
      credentials: 'same-origin',
      cache: 'no-cache'
    }).catch(err => {
      console.error(err)
    })
    show.value = false
  }
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.bonus-infusion {
  min-height: 15em;
}
</style>
