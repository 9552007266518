<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <modal-app m>
        <helpshift-article
          class="helpshift-article"
          :article="nameRef"
          :links-disabled="disableLinksRef"
          @close-failed="onError"
          @close="onClose"
        />
        <helpshift-article-styles />
      </modal-app>
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { Ref, defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const HelpshiftArticle = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "helpshift-article" */ '@gd/helpshift-article' as any),
  loadingComponent: AsyncProgress,
})
const HelpshiftArticleStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "helpshift-article" */ './HelpshiftArticleStyles.vue'),
})

const disableLinksRef = ref(true)
const show = ref(false)
const nameRef: Ref<string | undefined> = ref(undefined)

events.$on('helpshift-article', ({ name, disableLinks }: { name: string; disableLinks: boolean }) => {
  nameRef.value = name
  if (disableLinks !== undefined) {
    disableLinksRef.value = disableLinks
  }
  show.value = true
})

events.$on('close-modals', () => {
  show.value = false
})

const onClose = () => {
  show.value = false
}

const onError = () => {
  onClose()
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.customer-service-app {
  min-height: 15em;
}
</style>
