<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose" m>
      <modal-app m>
        <modal-layout>
          <modal-content class="user-comparison-wrapper">
            <user-comparison
              class="user-comparison"
              :gametype="configRef.gametype"
              :game-name="configRef.gameName"
              :opponent-id="configRef.opponentId"
            />
            <user-comparison-styles />
          </modal-content>
        </modal-layout>
      </modal-app>
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { Ref, defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const UserComparison = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "user-comparison" */ '@gd/user-comparison'),
  loadingComponent: AsyncProgress,
})
const UserComparisonStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "user-comparison" */ './UserComparisonStyles.vue'),
})

interface Comparison {
  gameName: string
  gametype: string
  opponentId: string
}

const show = ref(false)
const configRef: Ref<Comparison> = ref({
  gameName: '',
  gametype: '',
  opponentId: '',
})

events.$on('user-comparison', (config: { gameName: string; gametype: string; opponentId: string }) => {
  configRef.value = config
  show.value = true
})

events.$on('close-modals', () => {
  show.value = false
})

const onClose = () => {
  show.value = false
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.user-comparison-wrapper,
.user-comparison {
  min-height: 10em;
}
</style>
