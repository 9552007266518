<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeESC="onClose" @closeBG="onClose" @close="onClose">
      <change-password class="change-password" @change="onSuccess" @close="onClose" size="s" />
      <change-password-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const ChangePassword = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "change-password" */ '@gd/change-password'),
  loadingComponent: AsyncProgress,
})
const ChangePasswordStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "change-password" */ './ChangePasswordStyles.vue'),
})

const show = ref(false)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'changePassword') {
        show.value = true
      }
    })
  }
})

const onSuccess = () => {
  show.value = false
  fetch('/gd/rest/me/notifications/ack/changePassword', {
    method: 'POST',
    credentials: 'same-origin',
    cache: 'no-cache',
  })
    .then(() => {
      location.href = '/gd/playerProfile/changePassword.xhtml'
    })
    .catch((err) => {
      console.error(err)
    })
}

const onClose = () => {
  fetch('/gd/rest/me/notifications/changePassword', {
    method: 'DELETE',
    credentials: 'same-origin',
    cache: 'no-cache',
  }).catch((err) => {
    console.error(err)
  })
  show.value = false
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.change-password {
  min-height: 15em;
}
</style>
