<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose" m>
      <loyalty-wood class="loyalty-wood" :percentage="percentage" @close="onClose" />
      <loyalty-wood-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const LoyaltyWood = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "loyalty-wood" */ '@gd/loyalty-wood'),
  loadingComponent: AsyncProgress,
})
const LoyaltyWoodStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "loyalty-wood" */ './LoyaltyWoodStyles.vue'),
})

const show = ref(false)
const percentage = ref(0)

events.$on('loyalty-wood', (config: { percentage: number }) => {
  percentage.value = config.percentage
  show.value = true
})

const onClose = () => {
  show.value = false
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.loyalty-wood-wrapper,
.loyalty-wood {
  min-height: 14em;
  min-width: 14em;
}
</style>
