<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeESC="onClose" @close="onClose">
      <freeplay :type="type" @close="closeFreeplay" size="m" />
      <freeplay-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { Ref, defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'
import { jslog } from '@gd/jslog'

const freeplay = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ '@gd/freeplay'),
  loadingComponent: AsyncProgress,
})
const freeplayStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ './FreeplayModalStyles.vue'),
})

const show = ref(false)
const type: Ref<'userBirthday' | 'gdBirthday' | 'freeplay'> = ref('freeplay')

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'freeplay' || item.type === 'gdBirthday' || item.type === 'userBirthday') {
        type.value = item.type
        show.value = true
      }
    })
  }
})

const closeFreeplay = ({
  reason,
  err,
  type,
}: {
  reason?: string
  err: string
  type?: 'userBirthday' | 'gdBirthday' | 'freeplay'
}) => {
  if (reason) {
    // error hapens, close modal:
    show.value = false
    jslog.send({ name: 'freeplay-modal', error: new Error(err), data: { reason } })
  } else if (type) {
    // will be called when the submit button in modal is clicked (hurray... e.g. for DK users)
    fetch('/gd/rest/me/notifications/' + type, {
      method: 'delete',
      credentials: 'same-origin',
      cache: 'no-cache',
    }).catch((err) => {
      console.error(err)
    })
  }
  // close anyway
  show.value = false
}

const onClose = () => {
  fetch('/gd/rest/me/notifications/' + type.value, {
    method: 'delete',
    credentials: 'same-origin',
    cache: 'no-cache',
  }).catch((err) => {
    console.error(err)
  })
  show.value = false
}
</script>

<style lang="less" scoped></style>
