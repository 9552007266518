<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <user-data-update :type="type" class="userdata-update-modal" @onSuccess="onSuccess" size="m" />
      <user-data-update-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import events from '@gd/event-bus'
import AsyncProgress from './AsyncProgress.vue'
import { Ref, defineAsyncComponent, ref } from 'vue'

const UserDataUpdate = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "address-update" */ '@gd/address-update'),
  loadingComponent: AsyncProgress,
})
const UserDataUpdateStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "address-update" */ './AddressUpdateModalStyles.vue')
})

const show = ref(false)
const type: Ref<GD_notifications.AddressUpdateType | GD_notifications.MailUpdateType | null> = ref(null)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'addressUpdate' || item.type === 'mailUpdate') {
        type.value = item.type
        show.value = true
      }
    })
  }
})

const onSuccess = () => {
  show.value = false
}

const onClose = () => {
  show.value = false
  if (type.value === 'addressUpdate' || type.value === 'mailUpdate') {
    fetch(`/gd/rest/me/notifications/${type.value}`, {
      method: 'delete',
      credentials: 'same-origin',
      cache: 'no-cache',
    }).catch((err) => {
      console.error(err)
    })
  }
}
</script>

<style lang="less" scoped>
@import '~@npm/@gd/common-styles/dist/font.less';
.module-loader&::v-deep .gd-modal-component-body,
.userdata-update-modal {
  min-height: 15em;
}
</style>
