<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <modal-app l>
        <minileague-howto class="minileague-howto-app" @close-failed="onError" />
        <minileague-howto-styles />
      </modal-app>
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const MinileagueHowto = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "minileague-howto" */ '@gd/minileague-howto' as any),
  loadingComponent: AsyncProgress,
})
const MinileagueHowtoStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "minileague-howto" */ './MinileagueHowtoStyles.vue'),
})

const show = ref(false)

events.$on('minileague-howto', () => {
  show.value = true
})

const onClose = () => {
  show.value = false
}

const onError = () => {
  onClose()
}
</script>

<style lang="less" scoped>
.module-loader&::v-deep .gd-modal-component-body,
.minileague-howto-app {
  font-size: 12px;
  max-width: 54.5em;
  min-height: 15em;
}
</style>
