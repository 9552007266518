<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <newsletter size="s" @close="onNewsletterClose" />
      <newsletter-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const newsletter = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ '@gd/newsletter'),
  loadingComponent: AsyncProgress,
})
const newsletterStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ './NewsletterModalStyles.vue'),
})

const show = ref(false)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if ((item.type as GD_notifications.NewsletterModalType) === 'newsletterModal') {
        show.value = true
      }
    })
  }
})

const onClose = () => {
  fetch('/gd/rest/me/notifications/newsletterModal', {
    method: 'delete',
    credentials: 'same-origin',
    cache: 'no-cache',
  }).catch((err) => {
    console.error(err)
  })
  show.value = false
}

const onNewsletterClose = () => {
  show.value = false
}
</script>

<style lang="less" scoped></style>
